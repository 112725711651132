<template>
  <div class="main">
    <el-button type="primary" @click.prevent="login">登录</el-button>
  </div>
</template>

<script>
// import { ChatSDK } from "@/views/liveChat/chatApi.js";
import AccountsSDK from "@livechat/accounts-sdk";
export default {
  name: "liveChatLogin",
  data() {
    return {
      chatLocalToken: localStorage.getItem("liveChatToken") || "",
      instance:''
    };
  },
  mounted() {
    if (this.chatLocalToken) {
      this.$router.push("/liveChat");
    }
  },
  methods: {
    login() {
      this.instance = new AccountsSDK({
        client_id: "6a6f06d6bf116dc77fdec589868cc7e4", 
        popup_flow: "manual",
        prompt: "consent",
      });
      this.instance
        .popup()
        .authorize()
        .then((authorizeData) => {
          const transaction = this.instance.verify(authorizeData);
           localStorage.setItem("liveChatToken", authorizeData.access_token);
          this.$router.push("/liveChat");
         
          if (transaction != null) {
            // authorization success
            // authorizeData contains `accessToken` or `code`
            // transaction contains state and optional code_verifier (code + PKCE)
              "User access token: " + transaction,
              transaction.accessToken
          
            // document.getElementById('login-button').style.display = "none"
          } 
        })
        .catch((e) => {
          this.$message({
            showClose: true,
            message: e.exception_details,
            type: "error",
          });
          //console.error("Failed to authorize user", e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  background: #fff;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>